/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Tabs, Tab } from 'react-bootstrap';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesCrystal: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dream Realm - Crystal Beetle</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_crystal.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Crystal Beetle</h1>
          <h2>
            Dream Realm Crystal Beetle guide that explains the boss mechanics
            and how to beat it!
          </h2>
          <p>
            Last updated: <strong>17/Feb/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Crystal Beetle " />
        <StaticImage
          src="../../../images/afk/generic/boss_beetle.webp"
          alt="Guides"
        />
        <p>
          <strong>Crystal Beetle</strong> is one of the 8 available Dream Realm
          bosses you will encounter in AFK Journey who was introduced to the
          game in Season 2. In this guide we will go over the boss mechanics and
          the best characters and teams to use against him.
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Season 3 Teams" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best Team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best Team (Whale)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/crystal3_1.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>半月Halfmoon / 花开见神 (Yaphalla)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                acts as secondary carry and energy regen source.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="harak" enablePopover /> is the
                main carry.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="ludovic" enablePopover />{' '}
                  can be used as an alternative.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                  can be used as an alternative.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="odie" enablePopover /> can
                  be used as an alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="elijah-and-lailah"
                  enablePopover
                />{' '}
                act as buffer and healer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="sonja" enablePopover /> can
                  be used as an alternative.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="koko" enablePopover /> can
                  be used as an alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required).
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as buffer and healer.
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Bladesummon Spell" /> - Best overall
                  performance.
                </li>
                <li>
                  <AFKItem name="Harmonic Spell" /> - Good alternative.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<>Team #2 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #2 - Alt Team</h5>
            <StaticImage
              src="../../../images/afk/dream_s/crystal3_2.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>ProEpi (Yaphalla)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                acts as main carry and energy regen source.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="ludovic" enablePopover /> acts
                as secondary carry and healer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="odie" enablePopover /> can
                  be used as an alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="elijah-and-lailah"
                  enablePopover
                />{' '}
                act as buffer and healer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="sonja" enablePopover /> can
                  be used as an alternative.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="koko" enablePopover /> can
                  be used as an alternative.
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="mikola" enablePopover /> can
                  be used as an alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as buffer and healer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover /> acts
                as buffer.
              </li>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Harmonic Spell" /> - Best overall performance.
                </li>
                <li>
                  <AFKItem name="Surging Spell" /> - Good alternative.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team3"
            title={<>Team #3 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #3 - Alt Team (Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/crystal3_3.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>ELisyonN (Yaphalla)</strong>.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                the main damage carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> acts as
                secondary damage dealer.
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="marilee" enablePopover />{' '}
                  can be used as an alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as secondary damage dealer and buffer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover /> acts as
                buffer and healer.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required).
              </li>
              <ul className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="mikola" enablePopover /> can
                  be used as an alternative at a small damage loss.
                </li>
                <li>
                  <AFKCharacter
                    mode="inline"
                    slug="smokey-and-meerky"
                    enablePopover
                  />{' '}
                  can be used as an alternative.
                </li>
              </ul>
              <li>Artifacts:</li>
              <ul className="bigger-margin">
                <li>
                  <AFKItem name="Bladesummon Spell" /> - Best overall
                  performance.
                </li>
                <li>
                  <AFKItem name="Harmonic Spell" /> - Good alternative.
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesCrystal;

export const Head: React.FC = () => (
  <Seo
    title="Crystal Beetle | AFK Journey | Prydwen Institute"
    description="Dream Realm Crystal Beetle guide that explains the boss mechanics and how to beat it!"
    game="afk"
  />
);
